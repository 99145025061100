<template>
  <component :is="htmlTag" class="labelHolder">
    <VText cfg="sans/16/medium" class="label" data-testid="label">
      <slot />
    </VText>
    <VText
      v-if="$slots.subLabel"
      cfg="sans/14/medium"
      color="black"
      data-testid="labelAnnotation"
    >
      <slot name="subLabel" />
    </VText>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import VText from '~/components/ui/VText.vue'

import type HtmlTag from '~/types/htmlTag'
import type { PropType } from 'vue'

type LabelHtmlTag = Extract<HtmlTag, 'div' | 'label'>

/**
 * Displays a label/title with the same styles as an input label but not the same markup.
 * If you're making a label for an input, use the "LabelInput" component instead.
 *
 * @example
 * <VLabel>
 *   Music Genres
 *   <template #subLabel>(optional)</template>
 * </VLabel>
 */
export default defineComponent({
  name: 'VLabel',
  components: {
    VText,
  },
  props: {
    /** .
     * The html tag to wrap the underlying content with.
     * Defaults to "div".
     *
     * @values div, label
     */
    htmlTag: {
      type: String as PropType<LabelHtmlTag>,
      required: false,
      default() {
        return 'div'
      },
    },
  },
})
</script>

<style lang="scss" scoped>
.label {
  @apply tw-mr-2 tw-min-h-[20px];
}

.labelHolder {
  @apply tw-relative tw-flex tw-w-full tw-flex-wrap tw-items-center tw-justify-start tw-leading-5;
}
</style>
